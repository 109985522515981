export enum FormItemType {
    GuestNumber,
    Turn,
    Date,
    SlotTime,
    WaitListTime,
    FirstName,
    LastName,
    Email,
    Phone,
    AgreementKids,
    AgreementAwaiting,
    AgreementPartiesPrepaymentOnWeekend,
    AgreementPartiesPrepaymentOnWeekday,
    DateWithoutSlots,
    GuestNumberForParties,
    BookButton,
    WaitListButton,
    PartyButton,
    AgreementOnOrTwoPrepaymentOnWeekend,
    AgreementOnOrTwoPrepaymentOnWeekday,
    Empty,
    AgreementBirchGuestPrepayment,
    AgreementFullPrepayment,
    AgreementOneThousandPrepayment,
    AgreementNewYearPrepayment
}